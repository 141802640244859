import React from 'react';
import SEO from '../components/SEO';

import Article from '../components/Articles/Trap';

export default function ({}) {
  return (
    <>
      <SEO
        title={'The 1,000-kilowatt Trap'}
        description={
          'Is your fixed rate electric plan costing you more than they said it would? It could be because that ‘really low price’ you saw only applies if you use exactly 1,000 kilowatts. Use 1 kilowatt less and your rate can sometimes double.'
        }
        brand={'sc'}
      />

      <Article />
    </>
  );
}
