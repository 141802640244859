import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

import * as webSocketActions from '../../../redux/actions/webSocketActions';

import TOC from '../../../components/TOC';

import SCLandingWrapper from '../../SCLandingWrapper';

import ThousandKWhTrap from '../../LandingSC/ThousandKWhTrap';
import ZipCodeField from '../ZipCodeField';

import '../index.css';
import './index.css';

function Article({ siteConfig }) {
  return (
    <div className="article-container trap">
      <ZipCodeField mobile buttonLabel={'Compare Rates'} />

      <h1>
        <div>
          <strong>
            Is your "fixed" electric rate costing more than they said it would?
          </strong>
        </div>
      </h1>
      <div className="page-container text-container full sc-landing">
        <h1>The 1,000-kilowatt Trap</h1>

        <div className="page">
          <div className="inner">
            <ThousandKWhTrap
              article
              bottomText={
                <>
                  <p>
                    <strong>
                      Scroll down to see a list of the current plans available.{' '}
                      &nbsp;&nbsp;
                    </strong>
                    <span className="hide-desktop">
                      <br />
                      <br />
                    </span>
                    <strong>
                      <em className="contract-text">In a contract? </em>
                      &nbsp;&nbsp;
                      <a href={'/switch'} className="link">
                        Click here
                      </a>{' '}
                      to use our Switch Calculator to see if you qualify for a
                      switch
                    </strong>
                  </p>
                </>
              }
            />
          </div>
        </div>
      </div>
      <SCLandingWrapper city={'dallas'} noHeader />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Article);
